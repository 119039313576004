body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  position: absolute !important;
  width: 100%;
}
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.point {
  float: left;
  width: 8px;
  height: 8px;
  opacity: 0.5;
  cursor: pointer;
  pointer-events: auto;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin: 6px auto;
  background: #1DA57A;
  border-radius: 100%;
}
.point.active {
  opacity: 1;
  background: #1DA57A;
}
.point-wrapper {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 16px;
  width: 10px;
  display: flex;
  height: 100%;
  align-items: center;
  pointer-events: none;
}
.point-left {
  left: 16px;
}
.point-rect {
  border-radius: 0;
}
.point-prismatic {
  border-radius: 0;
  transform: rotate(45deg);
}
.point-stroke {
  border: 2px solid #1DA57A;
  background: transparent;
}
.point-small {
  width: 6px;
}
.point-small.point {
  height: 6px;
}
.point-large {
  width: 10px;
}
.point-large.point {
  height: 10px;
}
@media screen and (max-width: 767px) {
  .point-wrapper {
    display: none;
  }
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 80px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner1 {
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #d15050;
  background: #fff;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
  color: black;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg0 {
  background: url("../assets/dandellion.jpeg") center;
}
.banner1 .bg1 {
  background: url("../assets/people.jpg") center;
}
.banner1 .bg2 {
  background: url("../assets/resto.jpeg") center;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  width: 550px;
  text-align: center;
  color: black;
}
.banner1 .banner1-title {
  width: 350px;
  left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner1 .banner1-content {
  margin-bottom: 20px;
  word-wrap: break-word;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 146, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  color: black;
}
.sub-title {
  font-size: 28px;
  font-weight: bold;
  margin: 10px -50px 10px 10px;
  color: white;
  opacity: 0.9;
}
@media screen and (max-width: 767px) {
  .banner1 .banner1-text-wrapper {
    width: 90%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
